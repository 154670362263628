import 'slick-carousel';

export class Slider{
  constructor(element, options, filter = false){
    this.element = element;
    this.$element = null;

    this.filter = filter;
    this.filtered = false;

    if (typeof(options) === 'object') {
      this.options = options;
    }

    this.element.addEventListener('slider.load', () => {
      this.onLoad();
    });
  }

  getDefaultOptions() {
    return {
      autoplay: true,
      dots: false,
      infinite: true,
      arrows: false,
    };
  }

  getOptions(options) {
    let defaultOptions = this.getDefaultOptions();

    if (typeof(options) === 'object') {
      options = Object.assign(defaultOptions, options);
    }

    return options;
  }

  onLoad() {
    this.$getElement().slick(this.getOptions(this.options));

    if (this.filter === true) {
      this.filterOdd();
      this.onResizeFilter();
    }
  }

  setOrientationEvent() {
    if(this.refreshInit === false) {
      window.addEventListener('orientationchange', () => {
        this.refreshSlider();
      }, false);

      this.refreshInit = true;
    }
  }

  $getElement() {
    if (this.$element === null) {
      this.$element = jQuery(this.element);
    }
    return this.$element;
  }

  refreshSlider() {
    console.log('refresh');
    this.$element.slick('unslick');

    setTimeout(() => {
      console.log('reload');
      this.onLoad();
    }, 1000);
  }

  // Au resize filtre les slides impaires pour le mobile
  onResizeFilter() {
    let resizeTimeout;

    window.onresize = () => {
      if(!!resizeTimeout){
        clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(() => {
        this.filterOdd();
      },1000);
    };
  }

  filterOdd() {
    if(window.innerWidth < 768 && !this.filtered) {
      this.$element.slick('slickFilter',':odd');
      this.filtered = true;
    } else if(window.innerWidth > 767 && this.filtered) {
      this.$element.slick('slickUnfilter');
      this.filtered = false;
    }
  }
}
