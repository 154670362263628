'use strict';

// Libs
import jQuery from 'jquery';
import 'bootstrap-sass';
import 'picturefill';
import 'babel-polyfill';
import 'custom-event-polyfill';

// Modules
// import * as helpers from './modules/helpers';
import { Slider } from './Slider';

jQuery(document).ready(function(){

  // Sliders
  var elements = document.querySelectorAll('.slick-slider');
  var event = new CustomEvent('slider.load');

  for (const element of elements) {
    let dataset = element.dataset;

    element.slider = new Slider(
      element,
      dataset.options ? JSON.parse(dataset.options) : {},
      dataset.filter ? JSON.parse(dataset.filter) : {},
      dataset.refresh ? JSON.parse(dataset.refresh) : {}
    );
    if (dataset.relatedElement) {
      element.slider.setRelatedElemeent(document.getElementById(dataset.relatedElement));
    }
  }

  for (const element of elements) {
    element.dispatchEvent(event);
  }

});
